<template>
    <Modal :has-footer="true" :footer-actions="modalActions">
        <template #title> Чеклар </template>
        <div>Modal</div>
    </Modal>
</template>

<script>
import Modal from "@Platon/components/extended/Modal"

export default {
    name: "ExampleModal",
    components: { Modal },

    computed: {
        modalActions() {
            return [
                {
                    icon: "fa fa-envelope",
                    text: "Message",
                    handler(hide) {
                        console.log(this.text)

                        setTimeout(hide, 2000)

                        return true
                    }
                },
                {
                    icon: "fa fa-user",
                    text: "User",
                    handler() {
                        console.log(this.text)

                        return true
                    }
                },
                {
                    icon: "fa fa-car",
                    text: "Car",
                    handler() {
                        console.log(this.text)

                        return true
                    }
                }
            ]
        }
    }
}
</script>

<style></style>
