<template>
    <Modal :footer-actions="modalActions" slot-class="p-0 overflow-auto" ref="modal">
        <template #title>
            <div>{{ modalTitle }}</div>
        </template>

        <div v-if="error" class="p-3">
            <div class="alert alert-danger mb-0">
                {{ eimzoNotFoundText }}
            </div>
        </div>

        <div v-else-if="certs.length === 0" class="p-3">
            <div class="alert alert-danger mb-0">
                {{ $l("platon.eimzo_certs_not_found", "Электрон калитлар топилмади") }}
            </div>
        </div>

        <EIMZOCert
            v-else
            @click.native="onSelected(cert)"
            :class="{ selected: selectedKey === cert }"
            @dblclick.native="
                () => {
                    onSelected(cert)
                    $refs.modal.handleAction(modalActions[0])
                }
            "
            :cert="cert"
            :key="index"
            v-for="(cert, index) in certs"
        />
    </Modal>
</template>

<script>
/**
 * @typedef EIMZOSignOptions
 *
 * @property {string} title
 */

import Modal from "../extended/Modal.vue"
import EIMZOCert from "@Platon/components/eimzo/EIMZOCert.vue"
import { DateTime } from "luxon"
import { isMobile } from "@Platon/core/helpers"
import EimzoMixin from "../login/EimzoMixin"

export default {
    name: "EIMZOSign",
    mixins: [EimzoMixin],

    components: {
        EIMZOCert,
        Modal
    },

    props: {
        signText: {
            type: [String, Array],
            default: ""
        },

        onSign: {
            type: Function
        },

        options: {
            type: Object,
            default: () => ({})
        },

        timestampProvider: {
            type: Function
        }
    },

    data() {
        return {
            error: false,
            certs: [],
            selectedKey: null,
            savedLoadedKey: this.options.loadedKey,

            client: null
        }
    },

    created() {
        this.client = this.getClient()

        this.init()
    },

    methods: {
        async init() {
            if (this.isDirectClient) {
                await this.installProjectApiKey()
            }

            try {
                await this.client.install()
                this.onCertsLoaded(await this.getAvailableCerts())
            } catch (e) {
                this.error = e
            }
        },
        async getAvailableCerts() {
            const { whiteList } = this.options
            let certs = await this.client.listAllUserKeys()
            if (!whiteList || !Array.isArray(whiteList)) return certs

            certs = certs.filter((cert) => {
                return whiteList.find((key) => cert.PINFL === key || cert.TIN === key)
            })

            return certs
        },

        onSelected(cert) {
            let isValid = DateTime.fromJSDate(cert.validTo).diffNow().as("seconds") > 0

            if (isValid) this.selectedKey = cert
        },

        /**
         * @param {Cert} a
         * @param {Cert} b
         */
        newKeysFirst(a, b) {
            let end1 = DateTime.fromJSDate(a.validTo)
            let end2 = DateTime.fromJSDate(b.validTo)

            return end2.ts - end1.ts
        },

        async handleSign() {
            if (!this.selectedKey) return

            try {
                const textsToSign = Array.isArray(this.signText) ? this.signText : [this.signText]

                for (let text of textsToSign) {
                    if (typeof text === "function") {
                        text = await text()
                    }

                    let signResult
                    let loadedKey

                    // try with saved loaded key
                    if (this.savedLoadedKey && this.savedLoadedKey.id) {
                        try {
                            signResult = await this.client.createPkcs7(
                                this.savedLoadedKey.id,
                                text,
                                this.timestampProvider,
                                this.options.hasChallenge
                            )
                            loadedKey = this.savedLoadedKey
                        } catch {
                            console.warn("Cannot sign with saved loaded key, ask for new")
                        }
                    }
                    if (!signResult) {
                        this.savedLoadedKey = loadedKey = await this.client.loadKey(this.selectedKey)
                        signResult = await this.client.createPkcs7(
                            loadedKey.id,
                            text,
                            this.timestampProvider,
                            this.options.hasChallenge
                        )
                    }

                    try {
                        this.onSign && this.onSign(signResult, { ...loadedKey })
                    } catch (e) {
                        console.warn("Error in onSign handler", e)
                    }
                }
            } catch (e) {
                console.warn(e)
                this.$platonApp.errorMessageBox("Калит пароли тўғри киритилганлиги текширинг")

                return true
            }
        },

        /**
         * @param {Cert[]} certs
         */
        onCertsLoaded(certs) {
            this.certs = certs.sort(this.newKeysFirst)

            if (this.options.certSerialNumber) {
                const lookingCert = this.options.certSerialNumber

                this.certs = this.certs.filter((c) => {
                    return c.serialNumber === lookingCert || c.PINFL == lookingCert || c.PINFL == lookingCert
                })

                if (this.certs.length > 0) {
                    this.selectedKey = this.certs[0]

                    if (this.options.autoSign === true) {
                        this.$refs.modal.handleAction(this.modalActions[0])
                    }
                }
            }
        }
    },
    computed: {
        modalTitle() {
            return this.options.title || this.$l("platon.sign_with_eimzo", "E-IMZO орқали имзолаш")
        },

        eimzoNotFoundText() {
            let mobile =
                "Сизда RS Imzo иловаси ўрнатилмаган. RS Imzo иловасини юклаб олиш учун қуйидаги ўрнатиш тугмасини босинг."
            let desktop =
                "E-IMZO билан боғланишда хатолик. Сизда E-IMZO модули ёки E-IMZO браузери ўрнатилмаганга ўхшайди"

            return isMobile()
                ? this.$l("platon.eimzo_not_found_mobile", mobile)
                : this.$l("platon.eimzo_not_found_desktop", desktop)
        },

        modalActions() {
            return [
                this.error
                    ? {
                          text: isMobile()
                              ? this.$l("platon.eimzo_site_mobile", "RS Imzo ўрнатиш")
                              : this.$l("platon.eimzo_site_desktop", "E-IMZO сайти"),
                          icon: "fa fa-external-link-alt",
                          handler() {
                              isMobile()
                                  ? window.open("https://play.google.com/store/apps/details?id=uz.realsoft.rsimzo")
                                  : window.open("https://e-imzo.uz/")
                          }
                      }
                    : {
                          text: this.options.signText || this.$l("platon.eimzo_sign", "Имзолаш"),
                          icon: "fas fa-key",
                          handler: this.handleSign,
                          disabled: this.selectedKey == null
                      },
                { text: this.$l("platon.cancel", "Бекор қилиш") }
            ]
        }
    }
}
</script>
