<template>
    <div>
        <PlatonTopbar />

        <p-datepicker v-model="date" :config="dateConfig" />

        <div class="m-5">
            <multi-tab :tabs="mt1Tabs"></multi-tab>
        </div>

        <div class="row">
            <div class="col-6">
                <platon-chart :options="pyramidChartData"></platon-chart>
            </div>
            <div class="col-6">
                <platon-chart :options="treeChartData"></platon-chart>
            </div>
        </div>

        <div @click="showExportModal">Export</div>

        <platon-link link="import/excel/test?a=5">import</platon-link>

        <p-input-data-list :options="['A', 'BA', 'CA']" />
        <!--		<b-button variant="primary" @click.stop="showMenu">-->
        <!--			Menu-->
        <!--		</b-button>-->

        <!--		<b-button variant="primary" @click="showModal">-->
        <!--			Show modal-->
        <!--		</b-button>-->

        <!--		<div class="card p-3 m-3">-->
        <!--			<MultiKeyValueControl v-model="keyModel" class="p-2 m-2" :definition="keyValue"/>-->
        <!--		</div>-->

        <button @click="sign()">EIMZO</button>

        <button @click="showMessage">MessageBox</button>

        <i style="font-size: 40px" :class="icon"></i>
        <input type="text" v-model="icon" ref="input" />

        <input type="text" ref="color" />

        <div style="width: 100vw; height: 50vh">
            <MapMarkerPicker :map-elements="mapElements" :markers="markers" @click="addMarker" />
        </div>

        <button @click="sendTestEvent">Send test event</button>
        <button @contextmenu.prevent.stop="showMenu($event)">Context menu</button>
        <div>{{ 1234 | time }}</div>
        <div>{{ 3680 | time }}</div>
        <div>{{ $f("time", 36820) }}</div>

        <div class="card m-4" style="width: 100%; height: 400px; overflow: scroll">
            <PTable
                table-class="m-4"
                :columns="tableColumns"
                :navigate-by-keyboard="true"
                :navigate-select-row="true"
                :table-data="tableData"
                :fixed-table="true"
            ></PTable>
        </div>

        {{ $apiUrl("v1/people/test") }}

        <div class="m-3">
            <MarkdownEditor v-model="markdown" />
        </div>

        <div class="m-3 card">
            <MarkdownPreview :text="markdown"></MarkdownPreview>
        </div>

        <!--		<div class="m-3">-->
        <!--			<MultiTable :titles="['Шартномалар', 'Тўловлар', 'Test']"-->
        <!--									:tables="['contract-direct', 'contract-direct-payments', 'contract-direct-invoice']"-->
        <!--									main-table="contract-direct"/>-->
        <!--		</div>-->

        <div @click="doConfirm">Confirm</div>
        <div @click="doConfirm2">Confirm2</div>

        <!--		<div>-->
        <!--			{{ keyModel }}-->
        <!--		</div>-->

        <!--		<div class="card p-3 m-3">-->
        <!--			<KeyValueControl v-model="keyModel2" class="p-2 m-2"/>-->
        <!--		</div>-->

        <!--		<div>-->
        <!--			{{ keyModel2 }}-->
        <!--		</div>-->

        <!--		&lt;!&ndash;		<input v-model="a">&ndash;&gt;-->

        <!--		<platon-table class="m-4" :show-title="false" table-name="tables" @onCellFavoriteClick="onClick"-->
        <!--									:navigate-by-keyboard="true" :table-filters="{a}"-->
        <!--									:extra-filters="{branch_ides: 1, test: 55}"-->
        <!--									:render="{favorite: renderFavorite}"-->
        <!--									@onRowDblClick="onClick">-->

        <!--		</platon-table>-->
    </div>
</template>

<script>
import ExampleModal from "@Platon/views/ExampleModal"
import MultiKeyValueControl from "@Platon/components/form/controls/MultiKeyValueControl"
import KeyValueControl from "@Platon/components/form/controls/KeyValueControl"
import MapPolygonControl from "@Platon/components/form/controls/MapPolygonControl"
import NavigationMixin from "@Platon/mixins/NavigationMixin"
import MarkdownEditor from "@Platon/components/form/controls/MarkdownEditor"
import MarkdownPreview from "@Platon/components/form/controls/MarkdownPreview"
import EIMZOSign from "@Platon/components/eimzo/EIMZOSign"
import IconPickerMixin from "@Platon/mixins/IconPickerMixin"
import MultiTable from "@Platon/components/table/MultiTable"
import PlatonInputDataList from "@Platon/components/extended/PlatonInputDataList"
import PlatonChart from "@Platon/components/chart/PlatonChart"
import ExportResourceDialog from "@Platon/components/misc/ExportResourceDialog"

export default {
    name: "TestTablePage",
    components: {
        PlatonInputDataList,
        PlatonChart,
        EIMZOSign,
        MultiTable,
        MarkdownPreview,
        MarkdownEditor,
        MapPolygonControl,
        KeyValueControl,
        MultiKeyValueControl
    },
    mixins: [NavigationMixin, IconPickerMixin],
    data() {
        return {
            mt1Tabs: [
                { name: "wait", type: "page", title: "Инфографика" },
                { name: "water_contracts", type: "table", title: "Шартномалар", main: true },
                { name: "water_contracts_det", type: "table", title: "Cув миқдорлари" },
                {
                    name: "water_source",
                    type: "table",
                    title: "Cув манбалари",
                    canRender: (name) => {
                        console.log("Checking")
                        return this.$route.query._level === "3"
                    }
                }
            ],
            pyramidChartData: {
                type: "pyramid",
                xPoints: { title: "val" },
                colors: ["#aaa", "#bbb", "#ccc", "#ddd", "cyan"],
                data: [
                    { title: "Manaviy hordiq", val: 10 },
                    { title: "Iqtisodiy erkinlik", val: 30 },
                    { title: "Oziq-ovqat", val: 70 },
                    { title: "Xavfsizlik", val: 100 }
                ],
                showSeriesLabels: true,
                showDataLabels: true
            },

            treeChartData: {
                type: "tree",
                pointFields: {
                    id: "id",
                    title: "name1",
                    value: "val",
                    parent: "int01"
                },
                colors: ["#f00", "#0f0", "#00f"],
                data: [
                    { id: 3, int01: null, name1: "Андижон вилояти", val: 5 },
                    { id: 6, int01: null, name1: "Бухоро вилояти", val: 3 },
                    { id: 8, int01: null, name1: "Жиззах вилояти", val: 19 },
                    { id: 301, int01: 3, name1: "Андижон шаҳри", val: 7 },
                    { id: 303, int01: 3, name1: "Хонобод шаҳри", val: 6 },
                    { id: 318, int01: 3, name1: "Пахтаобод тумани", val: 3 },
                    { id: 319, int01: 3, name1: "Хўжаобод тумани", val: 4 },
                    { id: 614, int01: 6, name1: "Қоровулбозор туман", val: 1 },
                    { id: 601, int01: 6, name1: "Бухоро шаҳри", val: 9 },
                    { id: 801, int01: 8, name1: "Арнасой тумани", val: 4 },
                    { id: 812, int01: 8, name1: "Жиззах шаҳри", val: 8 },
                    { id: 813, int01: 8, name1: "Янгиобод тумани", val: 6 }
                ],
                showSeriesLabels: true,
                showDataLabels: true
            },

            markdown: "# Hello",
            a: 5,

            keyModel: [],

            keyModel2: { name: "Adizbek", a: 5 },

            keyValue: [
                { key: "key", value: "Key" },
                { key: "value", value: "Value" }
            ],
            icon: "",
            date: null,
            dateConfig: {
                format: "DD.MM.YYYY",
                useCurrent: false
            },
            /**
             * @type MapPickerMarker[]
             */
            markers: [],

            tableColumns: [
                { dataField: "_rownum", title: "#", footer: "Жами:" },
                { dataField: "name", title: "Name", footer: "@_count" },
                { dataField: "favorite", title: "Fav", footer: "@_count", cellFormat: this.renderFavorite },
                { dataField: "age", title: "Age", footer: "@_sum" },
                {
                    dataField: "actions",
                    title: "Actions",
                    cellFormat: {
                        template: "<b>Actions</b>"
                    }
                }
            ],

            tableData: [
                { name: "Adizbek lorem ipsum sit amet ipsum sit amet", age: 25 },
                { name: "Alex", age: 27 },
                { name: "Doe", age: 17 },
                { name: "Doe", age: 17 },
                { name: "Doe", age: 17 },
                { name: "Doe", age: 17 },
                { name: "Doe", age: 17 },
                { name: "Doe", age: 17 },
                { name: "Doe", age: 17 },
                { name: "Doe", age: 17 },
                { name: "Doe", age: 17 },
                { name: "Doe", age: 17 },
                { name: "Doe", age: 17 },
                { name: "Doe", age: 17 },
                { name: "Doe", age: 17 },
                { name: "Doe", age: 17 },
                { name: "Doe", age: 17 }
            ],

            polygonData: {
                type: "Polygon",
                coordinates: [
                    [
                        [64.721146, 39.816975],
                        [64.722519, 39.823831],
                        [64.729729, 39.833586],
                        [64.745522, 39.848612],
                        [64.764748, 39.862054],
                        [64.777451, 39.862581],
                        [64.785004, 39.869959],
                        [64.786034, 39.862054],
                        [64.802513, 39.878654],
                        [64.81041, 39.901572],
                        [64.815216, 39.904732],
                        [64.848518, 39.919479],
                        [64.866714, 39.928695],
                        [64.882851, 39.928431],
                        [64.90242, 39.923166],
                        [64.907913, 39.913423],
                        [64.91684, 39.911843],
                        [64.942932, 39.879181],
                        [64.948425, 39.854147],
                        [64.953575, 39.837541],
                        [64.963188, 39.826468],
                        [64.965591, 39.819348],
                        [64.948425, 39.799832],
                        [64.946709, 39.789808],
                        [64.939499, 39.785851],
                        [64.933662, 39.784532],
                        [64.928513, 39.80247],
                        [64.916496, 39.804844],
                        [64.889374, 39.820667],
                        [64.879074, 39.815129],
                        [64.826202, 39.793765],
                        [64.820023, 39.801415],
                        [64.80011, 39.782949],
                        [64.781914, 39.786642],
                        [64.766121, 39.791391],
                        [64.751015, 39.795348],
                        [64.736938, 39.800096],
                        [64.721146, 39.816975]
                    ]
                ]
            },

            mapElements: [
                {
                    template: `
					<div>
					<l-tile-layer layer-type="overlay" v-bind="layers[0]"/>
					<l-tile-layer layer-type="overlay" v-bind="layers[1]"/>
					</div>
				`,

                    data() {
                        return {
                            layers: [
                                {
                                    url: "http://{s}/geoserver/gwc/service/wmts?layer=Geointellect%3Acache_1870&style=&tilematrixset=EPSG%3A3857&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng&TileMatrix=EPSG%3A3857%3A{z}&TileCol={x}&TileRow={y}",
                                    opacity: 0.7,
                                    // transparent: true,
                                    tileSize: 256,
                                    name: "Toshkent tarixi 1870",
                                    subdomains: ["geoserver.tashkent.uz", "geoserver1.tashkent.uz"],
                                    attribution: "Toshkent"
                                },

                                {
                                    url: "http://{s}/geoserver/gwc/service/wmts?layer=Geointellect%3Afran_1897&style=&tilematrixset=EPSG%3A3857&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng&TileMatrix=EPSG%3A3857%3A{z}&TileCol={x}&TileRow={y}",
                                    opacity: 0.7,
                                    // transparent: true,
                                    tileSize: 256,
                                    name: "Toshkent tarixi 1897",
                                    subdomains: ["geoserver.tashkent.uz", "geoserver1.tashkent.uz"],
                                    attribution: "Toshkent"
                                }
                            ]
                        }
                    }
                }
            ]
        }
    },

    mounted() {
        this.$onEvent("test-table", function () {
            console.log(arguments)
        })

        this.hotkey(["alt_digit7", "alt_numpad1"], () => {
            console.log("ALT+Digit1,7    ")
        })

        this.$nextTick(() => {
            this.injectIconPickerDialog(this.$refs.input, this, "icon")
        })
    },

    methods: {
        showExportModal() {
            this.$modal.show(ExportResourceDialog)
        },

        doConfirm() {
            this.confirmAction(this.showModal, {
                title: "<i>Hello</i>",
                text: "<u>It's html</u>",
                confirmText: "Roziman",
                rejectText: "Qarshiman",
                confirmIcon: "fa fa-times",
                rejectIcon: "fa fa-envelope",
                modalProps: {
                    headerClass: "bg-warning"
                },
                onReject() {
                    alert(1)
                }
            })
        },
        doConfirm2() {
            this.confirmAction(this.showModal, {
                text: "O'chirishni xohlaysizmi ?"
            })
        },
        addMarker(map, event) {
            this.markers.push({
                id: Math.random() * 1000000,
                onMarkerClick: (marker) => {
                    console.log(marker)
                },
                options: {
                    icon: new Date().getTime() % 2 === 0 ? window.MapMarkers.redIcon : window.MapMarkers.greenIcon
                },
                latLng: event.latlng,
                markerData: {
                    hello: "world"
                },
                popup: {
                    template:
                        '<b @click="clicked">Hello world {{marker}} <img src="https://wallpaper.dog/large/20457335.png" alt="" width="200px"> {{marker.markerData}}</b>',
                    props: {
                        marker: {}
                    },
                    methods: {
                        clicked() {
                            alert(1)
                        }
                    }
                },
                popupOptions: {
                    maxWidth: 700,
                    maxHeight: 500
                }
            })
        },

        showMessage() {
            this.$platonApp.messageBox("Tasdiqlang", {
                actions: [
                    { text: "Ok" },
                    {
                        text: "Do something",
                        icon: "fa fa-envelope",
                        handler: () => {
                            // action
                        }
                    }
                ]
            })
        },

        showMenu(e) {
            this.$platonApp.createContextMenuWithTitle(
                "It's title!",
                [
                    {
                        icon: "fa fa-envelope",
                        text: "Action1",
                        handler() {
                            alert("Clicked this")
                        }
                    },
                    {
                        icon: "fa fa-circle",
                        text: "Action2",
                        handler() {
                            alert("Clicked this2")
                        }
                    },

                    {
                        icon: "fas fa-book-medical",
                        text: "Book medical",
                        handler() {
                            alert("Book")
                        }
                    }
                ],
                e
            )
        },

        showModal() {
            this.$modal.show(
                ExampleModal,
                {},
                {
                    height: "auto",
                    width: "400px"
                }
            )
        },

        renderFavorite(value, data, col) {
            return {
                template: `
					<div @click="dos">123, {{ a }} {{ data }}</div>`,
                props: {
                    data: {}
                },

                data() {
                    return {
                        a: 1 + value
                    }
                },

                methods: {
                    dos() {
                        alert(1)
                    }
                }
            }
        },

        sendTestEvent() {
            this.$sendEvent("test-table", 1, 2, 3)
        },

        sign() {
            // this.$platonApp.signWithEIMZO(['Hello world', 'ASD'], (res) => {
            // 	console.log('Signing', res)
            // })

            const id = [1, 2, 3, 4]

            const contents = id.map((i) => {
                return new Promise((resolve) => {
                    resolve(i.toString())
                })
            })

            this.$platonApp.signWithEIMZO(contents, (res) => {
                console.log("Signing", res)
            })
        },

        onClick() {
            console.log({ here: "1", arguments })
        },

        renderTabLogic(tabName) {
            if (tabName === "water_source") return this.$route.query._level === "3"

            return true
        }
    }
}
</script>
